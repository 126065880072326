<template>
  <v-container class="d-flex justify-center align-center fill-height">
    <v-card class="pa-6 text-center" elevation="8" width="500">
      <v-icon size="64" class="mb-3" color="primary">mdi-timer-sand</v-icon>
      <h2 class="font-weight-bold mb-4">{{ title }}</h2>
      <p class="text-body-2 mb-4">
        {{ description }}
      </p>
      <p class="text-body-2 mb-2">We appreciate your patience and can't wait to welcome you soon!</p>
      <p class="font-italic">We've got a few more adjustments to make and will be ready by<br />April 1st!</p>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ComingSoon",
  props: {
    title: {
      type: String,
      default: "Racer Registration for Minors Coming Soon!"
    },
    description: {
      type: String,
      default: ""
    }
  }
};
</script>
